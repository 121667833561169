.container {
  min-height: 100vh;
  position: relative;
  padding: 240px 0;
  box-sizing: border-box;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}

.message {
  text-align: center;
  color: #fff;
}

.message-heading {
  font-family: "Share Tech Mono";
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: .7em;
  font-size: 2rem;
  padding: 0 0 0 1.4em;
}

.message-description {
  font-family: "Space Mono";
  line-height: 42px;
  font-size: 15px;
  letter-spacing: .15rem;
  padding: 0 20px;
  max-width: 600px;
  margin: auto;
}

.links {
  max-width: 600px;
  margin: 40px auto 0;
  text-align: center;
}

.links a {
  width: 170px;
  display: inline-block;
  padding: 15px 0;
  margin: 0 15px;
  border: 1px solid #ffffff;
  color: #ffffff;
  text-decoration: none;
  font-family: "Space Mono";
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: .1rem;
  position: relative;
}

.links a:before {
  content: '';
  height: 46px;
  background: #ffffff;
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  transition: all .3s;
}

.links a:after {
  transition: all .3s;
  z-index: 999;
  position: relative;
  content: "back to hompage";
}

.links a:hover:before {
  width: 170px;
}

.links a:hover:after {
  color: #000000;
}

.links a:nth-child(2) {
  background: #ffffff;
  color: #000000;
}

.links a:nth-child(2):before {
  background: #212121;
  left: 0;
}

.links a:nth-child(2):after {
  content: "report error";
}

.links a:nth-child(2):hover:after {
  color: #ffffff;
}

.social {
  position: absolute;
  bottom: 15px;
  left: 15px;
}

.social-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.social-list li {
  display: inline-block;
  margin: 5px 10px;
}

.social-list li a {
  color: #ffffff;
}

@media (max-width: 480px) {
  .message-heading {
    font-size: 1rem;
    margin-bottom: 30px;
  }
  .message-description {
    font-size: .7rem;
    line-height: 2rem;
  }
  .links a {
    margin: 10px;
    width: 280px;
  }
  .social {
    left: 50%;
    margin-left: -55px;
  }
}