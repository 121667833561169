// Fonts
@import url('https://fonts.googleapis.com/css2?family=Kameron:wght@400;700&display=swap');


// Utils
@import "./utils/reset";
@import "./utils/sizes";
@import "./utils/mixins";
@import "./utils/_settings";
// Layouts
@import "./layout/main-header.scss";
@import "./layout/main-footer";


// Components
@import "components/home/pres";
@import "./components/home/cardskill";
@import "./components/home/skills";
@import "./components/home/contact";
@import "./components/home/tech";
@import "./components/home/tech-explain";
@import "./components/home/value-think";
//@import "./components/home/partner";

//Pages
@import "./pages/home.scss";
@import "./pages/error/notfound";






