.contact--component{
  display: flex;
  flex-direction: column;
  padding:150px 100px;
  @include tablet{
    padding: 100px 50px;
  }
  @include mobile{
    padding: 50px 20px;
  }
  .title-first-component{
    font-weight: 700;
    font-size: 32px;
    line-height: 41px;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 100px;
    @include mobile{
      font-size: 25px;
      line-height: 35px;
      margin-bottom: 35px;
    }
  }
  .container-form{
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include small-device{
      flex-direction: column;
      justify-content: inherit;
    }

    .container-form-left{
      width: calc(60% - 30px);
      display: flex;
      flex-direction: column;
      @include small-device{
        width: 100%;
      }
      .title-content{
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        color: #FFFFFF;
        margin-bottom:20px;
        @include mobile{
          font-size: 18px;
          line-height: 22px;
          margin-bottom:10px;

        }
      }
      .subtitle-form-left{
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        @include mobile{
          font-size: 14px;
          line-height: 22px;
        }
        color: #FFFFFF;
      }
      .container-global-input{
        display: flex;
        flex-direction: column;
        .container-input{
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          input{
            background-color: transparent;
            border-top:1px solid transparent;
            border-left:1px solid transparent;
            border-right:1px solid transparent;
            border-bottom: 1px solid #FFFFFF;
            color: #FFFFFF;
            width: calc(50% - 25px);
            font-weight: 400;
            font-size: 16px;
            padding: 10px 3px;
            line-height: 24px;
            margin-bottom:40px;
            text-align: left;
            transition: 0.3s ease;
            @include mobile{
              width: calc(50% - 10px);
              font-size: 12px;
            }
            &:focus{
              outline: none;
              padding: 15px 3px;
            }
            &::placeholder{
              color: #FFFFFF;
            }
          }
        }
        textarea{
          background-color: transparent;
          border-top:1px solid transparent;
          border-left:1px solid transparent;
          border-right:1px solid transparent;
          border-bottom: 1px solid #FFFFFF;
          color: white;
          resize: none;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          text-align: left;
          margin-bottom: 30px;
          &:focus{
            outline: none;
            scale: 1.01;
            color: #FFFFFF;
          }
          &::placeholder{
            color: #FFFFFF;
          }
        }
        .container-right{
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          button{
            background: rgba(0, 220, 129, 0.5);
            font-weight: 700;
            font-size: 20px;
            border: 1px solid transparent;
            line-height: 51px;
            width: 200px;
            text-align: center;
            border-radius: 10px;
            cursor: pointer;
            padding: 5px 10px;
            color: #FFFFFF;
            transition: all .3s;
            @include mobile{
              width: 100px;
              font-size: 14px;
              padding: 2px 5px;
            }
            &:hover{
              background: rgba(0, 220, 129, 1);
              transform: scale(1.025);
            }
          }
        }
      }

    }
    img{
      width: calc(40% - 20px);
      height: 100%;
      @include tablet{
        width: 100%;
      }
      @include mobile{
        width: 260px;
        height: 300px;
      }
    }
  }
  .container-other-contact{
    display:flex;
    flex-direction: row;
    margin-top: 50px;
    .container-method-contact{
      display: flex;
      flex-direction: column;
      margin-right: 80px;
      .title-method-contact{
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        color: #FFFFFF;
        margin-bottom: 40px;
        @include mobile{
          font-size: 18px;
          line-height: 22px;
          margin-bottom: 20px;
        }
      }
      .subtitle-method-contact{
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: #FFFFFF;
        margin-bottom: 20px;
        @include mobile{
          font-size: 18px;
          line-height: 22px;
          margin-bottom: 10px;
        }
      }
      .container-réseaux{
        display:flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        position: relative;
        left: 10px;
        top: -10px;
        img{
          width: 30px;
          height: 30px;
          cursor: pointer;
          margin-right: 20px;
        }
      }
      &.position{
        position: relative;
        left:430px;
      }
    }
  }
}
#preloader {
  position: fixed;
  background-color: rgba(34, 34, 34, 0.80);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #003C3C;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
#loader:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: rgba(0, 220, 129, 0.5);
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}
#loader:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: rgba(0, 220, 129, 1);
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}
@-webkit-keyframes spin {
  0%   {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0%   {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
