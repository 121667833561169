.component--value-think{
  display: flex;
  flex-direction: row;
justify-content: space-between;
  margin-top: 100px;
  padding: 0 0 0 100px;
  @include tablet{
    padding: 0 50px;
  }
  @include mobile{
    padding: 0 20px;
    flex-direction: column;
    justify-content: inherit;
  }
  .container-text-value-explain{
    display: flex;
    flex-direction: column;
    .title-explain-value{
      font-style: normal;
      font-weight: 600;
      max-width: 600px;
      font-size: 56px;
      line-height: 63px;
      color: #00DC81;
      margin-bottom: 48px;
      @include tablet{
        font-size: 40px;
        line-height: 50px;
      }
      @include mobile{
        font-size: 20px;
        line-height: 30px;
      }
    }
    .container-subtitle-value-explain{
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      @include mobile{
        flex-direction: column;
        justify-content: inherit;
        margin-bottom: 10px;
      }
      .subtitle-container{
        display: flex;
        flex-direction: column;
        .title-subtitle{
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 32px;
          color: #FFFFFF;
          margin-bottom: 25px;
          @include mobile{
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 10px;
          }
        }
        .subtitle-text{
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          max-width: 400px;
          color: #FFFFFF;
          @include mobile{
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 10px;
          }
        }
      }
      .vide{
        width: 500px;
        height: 100px;
        background-color: transparent;
        @include mobile{
         display: none;
        }
      }
    }
  }
  img{
    @include tablet{
      display: none;
    }
    @include mobile{
      display: none;
    }
  }

}