.component--tech{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 100px 100px;
  @include tablet{
    display: none;
  }
  @include mobile{
    display: none;

  }
  .title-component{
    font-weight: 700;
    font-size: 36px;
    line-height: 46px;
    margin-bottom:44px;
    color: #FFFFFF;
  }
  .container-tech{
    display: flex;
    flex-direction: row;
    align-items:flex-start;
    justify-content: space-between;
    .container-left-tech{
      width: calc(33% - 20px);
      display: flex;
      flex-direction: column;
      .title-container{
        font-weight: 700;
        font-size: 48px;
        line-height: 61px;
        margin-bottom:32px;
        color: #FFFFFF;
        max-width:322px;
      }
      .subtitle-container{
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        color: #FFFFFF;
        margin-bottom:5px;
      }
    }
    .container-right-tech{
      display: flex;
      align-items: flex-start;
      justify-content: space-around;
    }
  }
}