.component--tech-explain{
  display: flex;
  flex-direction: column;
  padding:10px;
  margin-left: 20px;
  width: calc(33% - 20px);
  .title-tech-explain{
    font-weight: 700;
    font-size: 32px;
    line-height: 41px;
    color: #FFFFFF;
    margin-bottom: 71px;
  }
  .container-skill-tech{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
    .title-skills-tech{
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      margin-top: 15px;
      margin-bottom:34px;
      color: #FFFFFF;
    }
    .bar{
      width: 142px;
      height: 3px;
      border: 2px solid #FFFFFF;
    }
  }
}