.component--pres{
  display: flex;
  flex-direction:row;
  align-items: center;
  justify-content: space-between;
  padding: 240px 83px;
  .container-pres{
    display: flex;

    flex-direction:row;
    align-items: center;
    justify-content: space-between;
    .container-pres-img{
      width: calc(50% - 80px);
      max-width:573px;
      position: relative;
      max-height: 400px;
      margin-right: 163px;
      &.expt{
        margin-right:0;
        margin-left:163px;
      }
    }
    .other-rate{
      width: calc(50% - 80px);
      display:flex;
      position: relative;
      flex-direction: column;
      align-items: center;

      .title-first {
        font-weight: 700;
        font-size: 32px;
        line-height: 41px;
        text-align: center;
        margin-bottom: 52px;
      }
      .subtitle{
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        margin-bottom: 20px;
      }
      .button{
        margin-top: 14px;
        background: #00DC81;
        border-radius: 20px;
        padding:12px 15px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        cursor: pointer;
        color: #FFFFFF;
      }
    }
  }
}