.component--card-skill{
  display: flex;
  flex-direction:column;
  align-items: center;
  padding: 30px 35px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: calc(30% - 24px);
  margin-right: 32px;
  margin-bottom: 27px;
  cursor: pointer;
  &:nth-child(3n + 3){
    margin-right: 32px;
  }
  img{
    max-width:100px;
    max-height:66px;
    margin-bottom: 10px;
  }
  .title-first-card-skill{
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: #000000;
    max-width:240px;
    margin-bottom: 20px;
  }
  .subtitle-card-skill{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #000000;
    margin-bottom: 48px;
  }
  .button-card-skill{
    width:40px;
    height:37px;
    position:relative;
    top: 20px;
  }
}