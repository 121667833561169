.main--footer{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding:20px 50px;
  @include mobile{
    align-items: center;
    justify-content: inherit;
    flex-direction: column;
    padding:20px;

  }
  .logo{
    font-size: 36px;
    line-height: 43px;
    margin-right: 30px;
    width: 250px;
    height: 50px;
    cursor: pointer;
    @include tablet{
      width: 180px;
      height: 50px;
    }
    @include mobile{
      width: 100px;
      height: 30px;
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
  .title-footer{
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    margin-right:20px;
    @include mobile{
      font-size: 12px;
      line-height: 14px;
      margin-right:0px;
      margin-bottom: 20px;
    }
  }
  .img-social{
    Width :52.43px;
    Height : 44.86px;
    @include tablet{
      width: 45px;
      height: 35px;
    }
    @include mobile{
      width: 30px;
      height: 20px;
    }
  }
}