.view--home{
  width: 100%;
  padding: 50px 0;
  @include tablet{
    padding: 25px 0;
  }
  @include mobile{
    padding: 20px 0;
  }
  .header-section{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top:80px;
    padding: 0 150px;
    @include tablet{
      padding: 0 50px;
    }
    @include mobile{
      padding: 0 20px;
      flex-direction: column;
      justify-content: inherit;
      align-items: center;
      margin-top:50px;
    }
    .header-background{
      padding: 100px 50px;
      display:flex;
      flex-direction: column;
      justify-content: center;
      @include tablet{
        padding: 50px 25px;
      }
      @include mobile{
        padding: 20px 0px;
        justify-content: inherit;
        width: 267px;
      }
      .title-first{
        font-style: normal;
        font-weight: 400;
        font-size: 64px;
        line-height: 72px;
        max-width: 300px;
        color: #00DC81;
        @include tablet{
          font-size: 40px;
          line-height: 50px;
        }
        @include mobile{
          font-size: 20px;
          line-height: 22px;
        }
      }
      .subtitle{
        margin-top:40px;
        font-style: normal;
        font-weight: 400;
        width: 426px;
        font-size: 29px;
        line-height: 33px;
        color: #FFFFFF;
        @include tablet{
          width: 326px;
          font-size: 22px;
          line-height: 24px;
        }
        @include mobile{
          width: 100%;
          font-size: 14px;
          line-height: 16px;
          margin-top: 20px;
        }

      }
      .container-button{
        display: flex;
        flex-direction: row;
        margin-top: 40px;
        @include mobile{
          margin-top: 20px;
        }
        .button{
          display: flex;
          align-items: center;
          justify-content: center;
          width: 144px;
          background-color: #00DC81;
          padding: 10px;
          border-radius: 20px;
          cursor: pointer;
          text-align: center;
          font-size: 16px;
          line-height: 24px;
          @include mobile{
            font-size: 14px;
            line-height: 16px;
            width: 100px;
            padding:10px 25px;
          }
          &.over{
            background-color: transparent;
            width: 200px;
          }
          img{
            margin-left: 20px;
            width: 12px;
            height: 12px;
            @include mobile{
              margin-left: 10px;
              width: 6px;
              height: 6px;
            }
          }
        }
      }
    }
    .lottie{
      &.big{
        @include tablet{
          display: none;
        }
        @include mobile{
          display: none;
        }
      }
      &.tablet{
        @include big-device{
          display: none;
        }
        @include mobile{
          display: none;
        }
      }
      &.mobile{
        @include big-device{
          display: none;
        }
        @include tablet{
          display: none;
        }
      }
    }
  }
  .title-text{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 68px;
    line-height: 72px;
    margin: 90px auto;
    text-align: center;
    max-width: 1100px;
    @include tablet{
      font-size: 48px;
      line-height: 50px;
      padding: 0 50px;
    }
    @include mobile{
      font-size: 28px;
      line-height: 32px;
      padding: 0 20px;
    }

  }
  .container-enterprise{
    margin-bottom: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 80px;
    justify-content: space-around;
    padding: 0 50px;
    @include small-device{
      flex-direction: column;
    }
    img{
      width: 200px;
      height: 80px;
      margin-right: 30px;
      @include tablet{
        width: 60%;
        height: 60%;
        margin-right: 0px;
        margin-bottom: 100px;
      }
      @include mobile{
        width: 70%;
        height: 70%;
        margin-right: 0px;
        margin-bottom: 50px;
      }
    }
  }

}