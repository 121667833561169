.component--skills{
    display: flex;
    flex-direction: column;
    padding: 0 100px;
    margin: 170px auto;
    @include tablet{
      padding:0 50px;
    }
    @include mobile{
      padding:0 20px;
      margin: 70px auto;
    }
    .text-style-header{
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      line-height: 50px;
      color: #FFFFFF;
      max-width: 473px;
      @include tablet{
        text-align: center;
        font-size: 35px;
        line-height: 40px;
        max-width: 100%;
        margin-bottom: 40px;
      }
      @include mobile{
        text-align: center;
        font-size: 25px;
        line-height: 30px;
        max-width: 100%;
        margin-bottom: 20px;
      }
    }
    .container-skill{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 200px;
      @include small-device{
        flex-direction: column;
        align-items: center;
        justify-content: inherit;
      }
      @include tablet{
        margin-bottom: 100px;
      }
      @include mobile{
        margin-bottom: 50px;
      }
      &.reverse{
        @include small-device{
          flex-direction: column-reverse;
        }
      }
      .about-skill{
        display: flex;
        align-items: center;
        .container-text-skill{
          display: flex;
          flex-direction: column;
          max-width: 600px;
          @include small-device{
            max-width: 100%;
            margin-bottom: 30px;
          }

          .title-skill{
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
            color: #FFFFFF;
            margin-bottom: 25px;
            @include mobile{
              font-size: 16px;
              line-height: 24px;
              margin-bottom: 15px;
            }
          }
          .subtitle-skill{
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            color: #FFFFFF;
            margin-bottom: 10px;
            @include mobile{
              font-size: 14px;
              line-height: 18px;
            }
          }
        }
        &:before{
          content: " ";
          display: block;
          width: 8px;
          height: 150px;
          margin-right: 20px;
          background-color: #00DC81;
        }
      }
      .lotties-skill{
        &.big{
          @include tablet{
            display: none;
          }
          @include mobile{
            display: none;
          }
        }
        &.tablet{
          @include big-device{
            display: none;
          }
          @include mobile{
            display: none;
          }
        }
        &.mobile{
          @include big-device{
            display: none;
          }
          @include tablet{
            display: none;
          }
        }
      }

    }
}
.container-explain{
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  top: -150px;
  @include small-device{
    flex-direction: column;
    align-items: center;
    justify-content: inherit;
    top: 0px;
  }
  &.reverse{
    @include small-device{
      flex-direction: column-reverse;
    }
  }
  .lotties-skill{
    &.big{
      @include tablet{
        display: none;
      }
      @include mobile{
        display: none;
      }
    }
    &.tablet{
      @include big-device{
        display: none;
      }
      @include mobile{
        display: none;
      }
    }
    &.mobile{
      @include big-device{
        display: none;
      }
      @include tablet{
        display: none;
      }
    }
  }
  .container-text-explain{
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin-left: 80px;
    @include tablet{
      max-width: 100%;
      padding:0 50px;
      margin-left: 0px;
      margin-bottom: 30px;
    }
    @include mobile{
      max-width: 100%;
      padding:0 20px;
      margin-left: 0px;
      margin-bottom: 15px;
    }
    .title-explain{
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      line-height: 50px;
      color: #FFFFFF;
      margin-bottom: 30px;
      @include mobile{
        font-size: 25px;
        line-height: 28px;
        margin-bottom: 15px;
      }
    }
    .subtitle-explain{
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #FFFFFF;
      margin-bottom: 20px;
      @include mobile{
        font-size: 25px;
        line-height: 28px;
        margin-bottom: 15px;
      }
    }
    .text-button-explain{
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #00DC81;
      cursor: pointer;
      img{
        position: relative;
        top: 1px;
      }
    }
  }
}