.white-main-header{
  background-color:#151A2D;
  transition: all .3s;
}

.main-header{
  position:fixed;
  display: flex;
  width: 100%;
  flex-direction: row;
  z-index: 100;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px;
  .logo{
    font-size: 36px;
    line-height: 43px;
    margin-right: 30px;
    width: 250px;
    height: 50px;
    cursor: pointer;
    @include mobile{
      width: 100px;
      height: 30px;
    }
  }
  .link-container{
    display:flex;
    align-items: center;
    justify-content: space-between;
    .link{
      font-weight: 400;
      cursor: pointer;
      font-size: 20px;
      line-height: 24px;
      color: #FFFFFF;
      margin-right: 57px;
      &.responsive{
        @include mobile{
          display: none;
        }
        @include tablet{
          display: none;
        }
      }
      &.exp{
        margin-right: 87px;
      }
    }
    .button{
      cursor:pointer;
      padding:15px 45px;
      background:#00DC81;
      color: #FFFFFF;
      border-radius: 10px;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      @include mobile{
        font-size: 14px;
        line-height: 16px;
        padding:10px 25px;
      }
      &:hover {
        background:transparent;
        border: 1px solid #00DC81;
        color: #00DC81;
      }

    }
  }

}